@import '../../variables.scss';

@mixin dimensions($size, $reducedSize) {
    width: $reducedSize;
    @media (min-width: $screen-sm) {
        width: $size;
    }
}

.disabled {
    border: 1px solid transparent;
    filter: blur(.05rem) grayscale(100%);
}

.clickable {
    cursor: pointer;
}

.extra-large {
    @include dimensions(12rem, 9rem);
}

.large {
    @include dimensions(9rem, 7rem);
}

.medium {
    @include dimensions(7rem, 5rem);
}

.small {
    @include dimensions(5rem, 3rem);
}

.extra-small {
    @include dimensions(3rem, 2rem);
}

.hidden {
    visibility: hidden;
}