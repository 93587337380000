@import '../../variables.scss';

.pathToGlory {
    @media (min-width: $screen-sm) {
        display: none;
    }
}

p.container {
    padding: .5rem;
    text-align: center;
}

.container {
    margin: 1rem;
    display: flex;
    justify-content: center;
    .logo {
        &:hover {
            cursor: pointer;
        }
    }
    .date {
        @media (max-width: $screen-sm) {
            margin: 1rem;
        }
        font-size: .75rem;
        border: 1px solid white;
        display: flex;
        flex-direction: column;
        position: relative;
        flex-grow: 1;

        .winner {
            display: flex;
            justify-content: center;
            flex-direction: column;
            img {
                padding: .2rem;
                width: 40%;
                margin: auto;
            }
            .separator {
                border-bottom: 2px solid white;
                width: 100%;
            }
        }
        .header {
            width: 100%;
            background: black;
            border-bottom: 1px solid white;
            flex-grow: 0;
        }
        .matchup {
            padding: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 40%;
            }
            &.active {
                border: 3px dashed white;
                margin: 5px;
            }
        }
    }
}