@import '../../variables.scss';

.container {
    width: 100%;
    max-width: 65rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.header, .days {
    font-size: 1rem;
    @media (min-width: $screen-md) {
        font-size: 1.25rem;
    }
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    column-gap: .2rem;
    row-gap: .2rem;
    padding: .2rem;
    color: black;
}

.header {
    @media (max-width: $screen-sm) {
        display: none;
    }
}

.days {
    padding-top: 0px;
    @media (max-width: $screen-sm) {
        padding-top: .2rem;
    }
}
.day {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 2rem;
}

.day > .opponent {
    // flex-grow: 1;
    align-items: flex-end;
    display: flex;
    justify-content: center;
}

.controls {
    display: flex;
    justify-content: center;
    padding: 1rem;
}
.monthTitle {
    display: flex;
    align-items: center;
    padding: 0 1rem;
}