.game {
    .logo, .winnerLogo {
        border: 1px solid transparent;
        filter: blur(.05rem) grayscale(100%);
        &.active {
            filter: grayscale(0%);
            // background: radial-gradient(white, black);
            // box-shadow: 0px 0px 15px 15px black;
            // border-radius: 1rem;
        }
        &.highlight {
            background: radial-gradient(white, black);
            // border: 1px solid gray;
        }
    }
}